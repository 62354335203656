<template>
  <common-profile-settings-content-wrap :breadcrumbs="breadcrumbs">
    <common-forms-change-password />
  </common-profile-settings-content-wrap>
</template>

<script setup lang="ts">
  const breadcrumbs = useDashboardSettingsBreadcrumbs(
    computed(() => [
      {
        to: { name: 'dashboard-settings-password' },
        disabled: false,
        name: 'Password'
      }
    ])
  );

  useSeoMeta({
    title: 'Password Change'
  });
</script>
