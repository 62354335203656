<template>
  <form
    class="w-full"
    @submit="onSubmit"
  >
    <div class="grid gap-2.5 lg:mb-7 mb-5">
      <div>
        <ui-fields-password
          label="Enter Your Old Password"
          name="current_password"
        />
        <ui-button
          hide-space
          underline="always"
          variant="link"
          :loading="loading"
          class="text-xs lg:text-sm"
          @click="onForgotPassword()"
        >
          {{ $t('Forgot Your Password?') }}
        </ui-button>
      </div>
      <ui-fields-password
        label="New Password"
        name="password"
      />
      <ui-fields-password
        label="Confirm New Password"
        name="password_confirmation"
      />
    </div>
    <ui-button
      type="submit"
      full
      :loading="isSubmitting"
      text="Update"
    />
  </form>
</template>

<script setup lang="ts">
  import { object } from 'yup';
  import { useForm } from 'vee-validate';
  const apiRoutes = useApiRoutes();

  const { onForgotPassword, loading } = useForgotPassword(true);

  const initialValues = {
    current_password: '',
    new_password: '',
    new_password_confirmation: ''
  };

  const validationFields = useValidationRules();

  const { handleSubmit, isSubmitting } = useForm({
    validationSchema: object({
      current_password: validationFields.password(),
      password: validationFields.password(),
      password_confirmation: validationFields.passwordConfirm()
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(
      form => {
        return apiRoutes.profile.update(form);
      },
      initialValues,
      {
        successToast: MessagesSuccess.updatePassword,
        isResetForm: true
      }
    )
  );
</script>
