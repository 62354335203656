import type { StringSchema } from 'yup';
import { string, ref, boolean, array } from 'yup';

const RegexpVal = {
  alphaDash: /^[0-9A-Z_-]*$/i
} as const;

const useValidationRules = () => {
  const { t } = useI18n();
  const withRequired = (field: StringSchema, isRequired = false, fieldName?: string): StringSchema => {
    return isRequired ? field.required(t(MessagesError.isRequired, { name: fieldName })) : field;
  };

  const requiredString = (fieldName?: string): StringSchema => {
    return string().required(t(MessagesError.isRequired, { name: fieldName }));
  };

  const validationEmail = (isRequired = true): StringSchema => {
    return withRequired(string().email(t(MessagesError.invalidEmail)), isRequired, 'Email');
  };

  const passwordConfirm = (key = 'password') => {
    return requiredString().oneOf([ref(key)], t(MessagesError.confirmedPass));
  };

  const conditional = (message?: string) => {
    return boolean().oneOf([true], message);
  };

  const max = (_field?: any, _max = 255, isArr = false) => {
    const field = _field || (isArr ? array() : string());
    return field.max(_max, ({ max }: { max: number }) =>
      t(isArr ? MessagesError.itemMax : MessagesError.charactersMax, { max })
    );
  };

  const min = (_field?: any, _min = 8, isArr = false) => {
    const field = _field || (isArr ? array() : string());
    return field.min(_min, ({ min }: { min: number }) =>
      t(isArr ? MessagesError.itemMin : MessagesError.charactersMin, { min })
    );
  };
  const alphaDash = (_field?: any) => {
    const field = _field || string();
    return field.matches(RegexpVal.alphaDash, MessagesError.alphaDash);
  };

  const validationTitle = (fieldName?: string) => {
    return max(requiredString(fieldName), 255);
  };
  const validationSlug = (fieldName?: string) => {
    return alphaDash(max(requiredString(fieldName), 255));
  };

  const password = () => {
    return min(requiredString('Password'));
  };

  const arrayOf = (validationFields: any) => {
    return array().of(validationFields);
  };

  const url = (_field?: StringSchema) => {
    const field = _field || string();
    return field.url(MessagesError.url);
  };

  return {
    withRequired,
    requiredString,
    validationEmail,
    password,
    passwordConfirm,
    conditional,
    min,
    max,
    alphaDash,
    validationTitle,
    validationSlug,
    arrayOf,
    url
  };
};

export default useValidationRules;
