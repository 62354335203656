<template>
  <form
    class="w-full"
    @submit="onSubmit"
  >
    <div class="grid gap-2.5 lg:mb-7 mb-5">
      <ui-fields-text
        v-for="field in formFields"
        :key="field.name"
        :name="field.name"
        :label="field.label"
        :placeholder="field.placeholder"
      />
    </div>
    <ui-button
      type="submit"
      full
      :loading="isSubmitting"
      text="Update"
    />
  </form>
</template>

<script setup lang="ts">
  import { object } from 'yup';
  import { useForm } from 'vee-validate';

  const apiRoutes = useApiRoutes();
  const user = useSanctumUser<User>();

  const formFields = [
    {
      label: 'Twitter',
      placeholder: 'Enter Your Link',
      name: 'twitter'
    },
    {
      label: 'Facebook',
      placeholder: 'Enter Your Link',
      name: 'facebook'
    },
    {
      label: 'Instagram',
      placeholder: 'Enter Your Link',
      name: 'instagram'
    },
    {
      label: 'LinkedIn',
      placeholder: 'Enter Your Link',
      name: 'linkedIn'
    }
  ];

  const initialValues = computed(() => {
    const defaultValues = {
      twitter: '',
      facebook: '',
      instagram: '',
      linkedIn: ''
    };
    return getCleanFormValue(user.value?.profile?.social_links, defaultValues);
  });

  const validationFields = useValidationRules();

  const { handleSubmit, isSubmitting } = useForm({
    validationSchema: object({
      twitter: validationFields.url(),
      facebook: validationFields.url(),
      instagram: validationFields.url(),
      linkedIn: validationFields.url()
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(
      form => {
        const data = {
          profile: {
            social_links: useOmitBy(form, (val: string) => isEmpty(val))
          }
        };
        return apiRoutes.profile.update(data);
      },
      initialValues,
      {
        onSuccess(data: User) {
          user.value = data;
        },
        successToast: MessagesSuccess.updateProfile
      }
    )
  );
</script>
